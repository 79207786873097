var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('reportPayrollDetail.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('reportPayrollDetail.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('ts-loading-banner',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card border-0 bg-gray-900"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-gray-500"},[_c('b',[_vm._v(_vm._s(_vm.$t( 'reportPayrollDetail.filterCriteria' )))])])]),_c('div',{staticClass:"widget-list rounded-bottom"},[_c('div',{staticClass:"mb-3 mt-3"},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t( 'reportPayrollDetail.templateName' )))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.template_id),expression:"template_id"}],staticClass:"form-control form-select",class:{
                                                'is-invalid':
                                                    _vm.errors.has(
                                                        'template_id'
                                                    )
                                            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.template_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.onSelectedTemplate]}},[_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.$t('select'))+" ")]),_vm._l((_vm.reportTemplate),function(c){return _c('option',{key:c.template_id,domProps:{"value":c.template_id}},[_vm._v(" "+_vm._s(c.template_name)+" ")])})],2),(_vm.errors.has('template_id'))?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first('template_id'))+" ")]):_vm._e()])]),_c('div',{staticClass:"tw-flex tw--mx-2 mb-3"},[_c('div',{staticClass:"tw-w-1/2 tw-px-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t( 'reportPayrollDetail.cycleYear' )))]),_c('div',[_c('date-Picker',{class:{
                                                            'is-invalid':
                                                                _vm.errors.has(
                                                                    'cycle_year'
                                                                )
                                                        },attrs:{"value":_vm.cycle_year,"type":"year","placeholder":"Select Year","format":"yyyy"},on:{"on-change":_vm.cycleYearChange}})],1)])])]),_c('div',{staticClass:"tw-w-1/2 tw-px-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t( 'reportPayrollDetail.cycleMonth' )))]),_c('div',[_c('date-Picker',{class:{
                                                            'is-invalid':
                                                                _vm.errors.has(
                                                                    'cycle_month'
                                                                )
                                                        },attrs:{"value":_vm.cycle_month,"type":"month","placeholder":"Select Month","format":"MM"},on:{"on-change":_vm.cycleMonthChange}})],1)])])])]),_c('div',{staticClass:"mb-3"},[_c('RadioGroup',{model:{value:(_vm.id_type),callback:function ($$v) {_vm.id_type=$$v},expression:"id_type"}},_vm._l((_vm.calculateTypes),function(c,index){return _c('Radio',{key:index,staticClass:"mt-1",attrs:{"label":c.value,"border":""}},[_vm._v(" "+_vm._s(c.label)+" ")])}),1)],1),(_vm.id_type == 1)?_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"col-md-12"},[_c('label',[_vm._v(_vm._s(_vm.$t( 'reportPayrollDetail.orgChartStructure' )))]),_c('Select',{staticClass:"from-label",class:{
                                                'is-invalid': _vm.errors.has(
                                                    'org_chart_structure_id'
                                                )
                                            },attrs:{"filterable":"","loading":_vm.loading,"placeholder":_vm.$t('all'),"clearable":true},on:{"on-change":_vm.onOrgChange},model:{value:(_vm.org_chart_structure_id),callback:function ($$v) {_vm.org_chart_structure_id=$$v},expression:"org_chart_structure_id"}},_vm._l((_vm.orgStructure),function(org,index){return _c('Option',{key:index,attrs:{"value":org.org_chart_structure_id}},[_vm._v(" "+_vm._s(org.org_chart_structure)+" ")])}),1),(
                                                _vm.errors.has(
                                                    'org_chart_structure_id'
                                                )
                                            )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first( 'org_chart_structure_id' ))+" ")]):_vm._e()],1)]):_vm._e(),(_vm.id_type == 2)?_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"col-md-12"},[_c('label',[_vm._v(_vm._s(_vm.$t( 'reportPayrollDetail.shiftName' )))]),_c('Select',{staticClass:"from-label",attrs:{"filterable":"","loading":_vm.loading,"multiple":"","max-tag-count":2,"placeholder":_vm.$t('all')},on:{"on-change":_vm.onShiftChange},model:{value:(_vm.shift_id),callback:function ($$v) {_vm.shift_id=$$v},expression:"shift_id"}},_vm._l((_vm.shift),function(s,index){return _c('Option',{key:index,attrs:{"value":s.shift_id}},[_vm._v(" "+_vm._s(s.shift_name + '-' + s.shift_type)+" ")])}),1),(_vm.errors.has('shift_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('shift_id'))+" ")]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-label form-label tw-text-xs"},[_vm._v(_vm._s(_vm.$t('payslip.branchNameEn')))]),_c('ts-branch-filter',{attrs:{"isPlaceholder":true},on:{"filter":function (value) { return (_vm.branch_id = value); }}})],1),_c('div',{staticClass:"row tw-mb-6"},[_c('div',{staticClass:"col-sm-12"},[_c('label',[_vm._v(_vm._s(_vm.$t( 'payrollCalculation.employee' )))]),_c('div',[_c('Select',{staticClass:"from-label",attrs:{"filterable":"","remote":"","remote-method":_vm.searchEmployee,"loading":_vm.loading,"multiple":"","max-tag-count":2,"placeholder":_vm.$t('all')},model:{value:(_vm.employee_id),callback:function ($$v) {_vm.employee_id=$$v},expression:"employee_id"}},_vm._l((_vm.employeeProfile),function(emp,index){return _c('Option',{key:index,attrs:{"value":emp.employee_id}},[_vm._v(" "+_vm._s(emp.card_id + '-' + emp.employee_name_kh + '-' + emp.employee_name_en)+" ")])}),1),(
                                                    _vm.errors.has(
                                                        'employee_id'
                                                    )
                                                )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first( 'employee_id' ))+" ")]):_vm._e()],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.template_id),expression:"template_id"}],staticClass:"row mb-3"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group m-b-10"},[_c('form',[_c('fieldset',[_c('legend',[_vm._v(" "+_vm._s(_vm.$t( 'reportPayrollDetail.reportHeader' ))+" ")]),(
                                                            _vm.report_header.is_caption1
                                                        )?_c('div',{staticClass:"row mb-3 mt-2"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',[_vm._v(_vm._s(_vm.$t( 'reportPayrollDetail.captionOne' )))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                    _vm.report_header.caption1
                                                                ),expression:"\n                                                                    report_header.caption1\n                                                                "}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(
                                                                    _vm.report_header.caption1
                                                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.report_header, "caption1", $event.target.value)}}})])]):_vm._e(),(
                                                            _vm.report_header.is_caption2
                                                        )?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',[_vm._v(_vm._s(_vm.$t( 'reportPayrollDetail.captionTwo' )))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                    _vm.report_header.caption2
                                                                ),expression:"\n                                                                    report_header.caption2\n                                                                "}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(
                                                                    _vm.report_header.caption2
                                                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.report_header, "caption2", $event.target.value)}}})])]):_vm._e(),(
                                                            _vm.report_header.is_caption3
                                                        )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',[_vm._v(_vm._s(_vm.$t( 'reportPayrollDetail.captionThree' )))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                    _vm.report_header.caption3
                                                                ),expression:"\n                                                                    report_header.caption3\n                                                                "}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(
                                                                    _vm.report_header.caption3
                                                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.report_header, "caption3", $event.target.value)}}})])]):_vm._e()])])])])]),_c('div',{staticClass:"mb-3 tw-justify-end tw-flex tw-space-x-2"},[_c('ts-button',{attrs:{"outline":"","color":"success","waiting":_vm.exporting},on:{"click":function($event){$event.preventDefault();return _vm.exportExcel.apply(null, arguments)}}},[(!_vm.exporting)?_c('i',{staticClass:"far fa-file-excel"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('exportExcel')))]),_c('ts-button',{attrs:{"color":"primary","waiting":_vm.viewing},on:{"click":function($event){$event.preventDefault();return _vm.fetchData.apply(null, arguments)}}},[(!_vm.viewing)?_c('i',{staticClass:"far fa-eye"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('view')))])],1)])])]),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"overflow-auto",staticStyle:{"max-height":"75vh"}},[_c('ts-table',{attrs:{"columns":_vm.resources.length > 0
                                        ? ['No' ].concat( Object.values(_vm.columns))
                                        : ['Report viewer'],"records":_vm.resources,"loading":_vm.viewing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var record = ref.record;
                                        var index = ref.index;
return [_c('td',{staticClass:"tw-w-1"},[_vm._v(" "+_vm._s((_vm.pagination.currentPage - 1) * _vm.pagination.perPage + index + 1)+" ")]),_vm._l((_vm.columns),function(colName){return _c('td',{key:colName,staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.formatCurrencyAggregate( colName, record[colName] ))+" ")])})]}},(_vm.resources.length > 0)?{key:"tfooter",fn:function(){return [_c('th',{staticClass:"tw-bg-gray-100 tw-px-2 tw-py-3 tw-text-left tw-font-bold tw-whitespace-nowrap text-primary tw-bottom-0 tw-sticky"}),_vm._l((_vm.columns),function(colName){return _c('th',{key:colName,staticClass:"tw-bg-gray-100 tw-px-2 tw-py-3 tw-text-left tw-font-bold tw-whitespace-nowrap text-primary tw-bottom-0 tw-sticky"},[_vm._v(" "+_vm._s(_vm.showEachAggregate(colName))+" ")])})]},proxy:true}:null],null,true)})],1),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-pt-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }